<template>
<div>
  <custom-breadcrumb
    :pageTitle="$t('help.category.'+$route.params.category)"
    :breadcrumb="[
      {
        to: 'faq',
        text: this.$t('Help')
      },
    ]"
  ></custom-breadcrumb>

  <div
    id="knowledge-base-article"
  >
    <b-row v-if="kb_question">

      <!-- related question -->
      <b-col
        lg="3"
        md="5"
        order="2"
        order-md="1"
      >
        <b-card>
          <h6 class="kb-title">
            <feather-icon
              icon="InfoIcon"
              size="20"
              class="mr-50"
            />
            {{$t('help.related_questions')}}
          </h6>
          <b-list-group class="list-group-circle mt-1"
          >
            <b-list-group-item
              v-for="(que,index) in kb_question.relatedQuestions"
              :key="index"
              href="javascript:void(0)"
              class="text-body"
              @click="questionData()"
              :to="{ name: 'faq-question', params: { category: $route.params.category, slug: que.slug, id: que.id } }"
            >
              <span :style="que.id == $route.params.id ? 'color: #87bced':''">{{ que.question }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <!--/ related question -->

      <b-col
        lg="9"
        md="7"
        order="1"
        order-md="2"
      >
        <b-card>

          <!-- Title -->
          <b-card-title class="mb-1">
            <feather-icon
              icon="BookmarkIcon"
              size="21"
            />

            {{ kb_question.title }}
          </b-card-title>
          <b-card-text class="mb-2 text-muted">
            {{$t('resources.last_updated')}}: {{ kb_question.lastUpdated }}
          </b-card-text>

          <!-- Content -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="question-content"
            v-html="kb_question.content"
          />
          <!-- eslint-enable -->
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <!-- no result found -->
        <b-col
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Page not found
          </h4>
        </b-col>
        <!--/ no result found -->
    </b-row>

  </div>

</div>
</template>

<script>
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import questionData from './questionData'
import { getRoles } from '@/api/routes'

import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BCardText,
    BCardTitle,
    BListGroupItem,
    CustomBreadcrumb
  },
  data() {
    return {
      kb_question: {},
      userData: this.$store.getters['user/getUserData']
    }
  },
  created() {
    this.questionData() 
    this.getData();
    this.isLoggedIn();   
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){
      this.questionData()
    },
    '$route.params.slug': {
      handler: function(slug) {
        // console.log(slug,"slug")
        this.questionData()
      },
      deep: true,
      immediate: true
    }
  },
  methods:{
    questionData() {
      let lang = this.$store.getters["user/getLang"];
      let userData = this.$store.getters['user/getUserData'];
      let userRole = userData.role;

      if(questionData[lang]){
        let data = questionData[lang][this.$route.params.id];
        if (data) {
          this.kb_question = {
            ...data,
            relatedQuestions: data.relatedQuestions.filter(question => question.role ? question.role.includes(userRole) : false)
          };
        }
      } else {
        let data = questionData['es'][this.$route.params.id];
        if (data) {
          this.kb_question = {
            ...data,
            relatedQuestions: data.relatedQuestions.filter(question => question.role ? question.role.includes(userRole) : false)
          };
        }
      }
    },
    getData(){
      this.$http.post(getRoles, {}).then( response =>{
        // console.log('roles', response)
      }).catch(error => {
        // console.log("Err -> ", error)
      })
    },
    isLoggedIn(){
      let userData = this.$store.getters['user/getUserData']
      // console.log('User Role:', userData.role) 
    }, 
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
